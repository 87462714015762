import React,{useState,useEffect,useRef} from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom';
import InfinitySeatLayout from './InfinitySeatLayout'
import InfinitySeatLayoutMob from './InfinitySeatLayoutMob'
import useMediaQuery from '@mui/material/useMediaQuery';
import Helper from '../../util/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAngleDoubleDown, faUser, faEnvelope, faInfoCircle, faArrowLeft, faArrowRightLong, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
const InfinityViewSeat = (props) => {
    Modal.setAppElement('#root');
    const isMobile = useMediaQuery('(max-width: 767px)');
    const [showModal, setShowModal] = useState(false);
    const [continueClicked, setContinueClicked] = useState(false);
    const [selectedSeatType, setSelectedSeatType] = useState(1);

    const boardingPoints = props.boradingPoints ? props.boradingPoints.split('#') : [];
    const renderBoardingPoints = () => {
        return boardingPoints.map((point, index) => {
            const [id, location, time] = point.split('|'); // Splitting each point by '|'
            return (
                <div className="points_inner_div" key={id}>
                    <input 
                        type="radio" 
                        name="boarding_point_radio" 
                        id={`boarding_point_radio${id}`} 
                        checked={
                            bookingInfoDtls &&
                            bookingInfoDtls[props.busInfo.bus_id] &&
                            bookingInfoDtls[props.busInfo.bus_id]['journey_details'] &&
                            bookingInfoDtls[props.busInfo.bus_id]['journey_details']['boarding_point'] === location
                        }
                        onChange={(e)=>{chooseBoaring(location,time,id)}}
                    />
                    <label className="point_label_time" htmlFor={`boarding_point_radio${id}`}>{time}</label>
                    <label className="point_label_location" htmlFor={`boarding_point_radio${id}`}>{location}</label>
                </div>
            );
        });
    }
    const droppingPoints = props.droppingPoints ? props.droppingPoints.split('#') : [];
    const renderDroppingPoints = () => {
        return droppingPoints.map((point, index) => {
            const [id, location, time] = point.split('|');
            return (
                <div className="points_inner_div" key={id}>
                    <input 
                        type="radio" 
                        name="dropping_point_radio" 
                        id={`dropping_point_radio${id}`} 
                        checked={
                            bookingInfoDtls && 
                            bookingInfoDtls[props.busInfo.bus_id] && 
                            bookingInfoDtls[props.busInfo.bus_id]['journey_details'] 
                            && bookingInfoDtls[props.busInfo.bus_id]['journey_details']['dropping_point'] == location 
                        } 
                        onChange={(e)=>{chooseDropping(location,time,id)}}
                    />
                    <label className="point_label_time" htmlFor={`dropping_point_radio${id}`}>{time}</label>
                    <label className="point_label_location" htmlFor={`dropping_point_radio${id}`}>{location}</label>
                </div>
            );
        });
    }
    
    const [childState, setChildState] = useState([]);
    const handleChildStateChange = (stateFromChild) => {
        setChildState(stateFromChild);
    }; 
    const [showSecondStep,setShowSecondStep] = useState(1);
    const [blink,setBlink] = useState(0);
    const [allSeatDetails,setAllSeatDetails] = useState({
        boardingPoint:'',
        boardingTime:'',
        boardingId:'',
        droppingPoint:'',
        droppingTime:'',
        droppingId:''
    });
    const chooseBoaring = (val,time,id) => {
        setAllSeatDetails({...allSeatDetails,boardingPoint:val,boardingTime:time,boardingId:id});
        setShowSecondStep(2);
        setBlink(2);
    }
    const chooseDropping = (val,time,id) => {
        setBlink(1);
        setAllSeatDetails({...allSeatDetails,droppingPoint:val,droppingTime:time,droppingId:id});
    }    
    const closeBusViewDetails = () => {
        setAllSeatDetails({
            boardingPoint:'',
            boardingTime:'',
            boardingId:'',
            droppingPoint:'',
            droppingTime:'',
            droppingId:'',
        });
        setShowSecondStep(1);
        localStorage.removeItem('bookingDetails');
        localStorage.removeItem('bookingId');
        localStorage.removeItem('orderId');
        props.setShowViewSeat(false);
        setBlink(0);
    }

    const bookingInfoDtls = JSON.parse(localStorage.getItem('bookingDetails')) || {};
    let selectedSeatString = '';
    if(bookingInfoDtls[props.busInfo.bus_id]){
        if(bookingInfoDtls[props.busInfo.bus_id]['seats']){
            const seatNames = bookingInfoDtls[props.busInfo.bus_id]['seats'].map((seat) => seat.seatName);
            selectedSeatString = seatNames.join(',');
        }
    }
    const formRef = useRef(null);
    const navigate = useNavigate();
    const validatePhoneNumber = (phoneNumber) => {
        // Regular expression for a 10-digit phone number
        const phoneRegex = /^\d{10}$/;
        // Test the phone number against the regex
        return phoneRegex.test(phoneNumber);
    }
    const [befrNotfctnStatus, setBefrNotfctnStatus] = useState(false);
    const [phoneNumberValidationError, setPhoneNumberValidationError] = useState(false);
    const proceedToPreview = (event) => {
        event.preventDefault();
        const form = formRef.current;
        if (form.checkValidity()) {
            const formData = new FormData(form);
            const data = Object.fromEntries(formData.entries());
            // console.log('data', data.phoneNumber);
            // validate phone number
            const isValid = validatePhoneNumber(data.phoneNumber);
            if (!isValid) {
                setPhoneNumberValidationError(true);
                return false;
            }else{
                setPhoneNumberValidationError(false);
            }
            let bookingInfo = JSON.parse(localStorage.getItem('bookingDetails'));
            let journey_details = {
                'bus_name' : props.busInfo.bus_name,
                'route_id' : '',
                'boarding_point' : allSeatDetails.boardingPoint,
                'boarding_time' : allSeatDetails.boardingTime,
                'boarding_id' : allSeatDetails.boardingId,
                'dropping_point' : allSeatDetails.droppingPoint,
                'dropping_time' : allSeatDetails.droppingTime,
                'dropping_id' : allSeatDetails.droppingId,
                // 'bus_name' : props.bus_name,
                'bus_types' : props.bus_types,
                'source' : props.source,
                'departure_time' : props.departure_time,
                'destination' : props.destination,
                'arrival_time' : props.arrival_time,
                'duration' : props.duration,
                'doj' : props.doj,
                'bus_types' : props.bus_types,
            }
            bookingInfo[props.busInfo.bus_id]['bus_of'] = 'api';
            bookingInfo[props.busInfo.bus_id]['service_provider_id'] = props.busInfo.service_provider_id;
            bookingInfo[props.busInfo.bus_id]['passanger'] = data;
            bookingInfo[props.busInfo.bus_id]['journey_details'] = journey_details;
            localStorage.setItem('bookingDetails', JSON.stringify(bookingInfo));
            //redirect to preview page

            // window.scrollTo(0, 0);
            document.body.classList.remove('body-no-scroll');
            if(props.busInfo.booking_proceed_msg!='' && props.busInfo.booking_proceed_msg!='null' && props.busInfo.booking_proceed_msg!=null){
                setBefrNotfctnStatus(true);
                alert(props.busInfo.booking_proceed_msg);
                // return false;
            }else{
                setBefrNotfctnStatus(false);
            }
            navigate('/preview/'+props.busInfo.bus_id);
        }else{
            console.log('Form validation failed');
        }
    }

    useEffect(() => {
        if (props.showViewSeat && isMobile) {
            document.body.classList.add('body-no-scroll');
            setShowModal(true);
        }
    }, [props.showViewSeat, isMobile]);
    return (
        <>
            {
                !isMobile && 
                <div className=''>
                    <ViewSeatDiv className="view_seat_layouts" style={props.showViewSeat===true ? {display:''} : {display:'none'}}>
                        {
                            props.seatLayoutsLoading===true ?
                                <div className='before_book_seat'>
                                    <h5>Loading ......</h5>
                                </div>
                            :
                            <div className="before_book_seat" style={showSecondStep===3 ? {display:'none'} : {display:''}}>
                                <div className="dotted_border">
                                    <div className="no_of_seat_div">
                                        <div>
                                            {/* <span>(384)</span> */}
                                        </div>
                                        <div className="seat_color_div">
                                            <div className="color_div_upper">
                                                <span>Female</span>
                                                <div className="color_div bg-free-pink"></div>
                                            </div>
                                            <div className="color_div_upper">
                                                <span>Available</span>
                                                <div className="color_div bg-free-blue"></div>
                                            </div>
                                            <div className="color_div_upper">
                                                <span>Unavailable</span>
                                                <div className="color_div bg-gray"></div>
                                            </div>
                                            <div className="color_div_upper">
                                                <span>Selected</span>
                                                <div className="color_div bg-blue"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <span></span>
                                    <InfinitySeatLayout 
                                        ITSSeatDetails={props.ITSSeatDetails} 
                                        bus_id={props.busInfo.bus_id}
                                        onStateChange={handleChildStateChange}
                                    />
                                </div>
                                <div className="dotted_border_right_sec">
                                    <div className='seat_layout_close'>
                                        <FontAwesomeIcon icon={faTimes} onClick={() => { closeBusViewDetails(); props.setSelectedBusId(null);props.setBlogShowStatus(true) }}/>
                                    </div>
                                    {/* <div className="form_all_steps">
                                        <div className="first_step">
                                            <a href="/bus/Bhubaneswar-to-Keonjhar/19-07-2024">
                                                <div className="new_site_ribbon active">Boarding Point</div>
                                            </a>
                                        </div>
                                        <div className="second_step" style={{display:'none'}}>
                                            <a href="/bus/Bhubaneswar-to-Keonjhar/19-07-2024">
                                                <div className="new_site_ribbon">Dropping Point</div>
                                            </a>
                                        </div>
                                    </div> */}
                                    <div className='form_all_steps'>
                                        <div className={bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && blink===0  ? 'first_step blink_div' : 'first_step'}>
                                            <Link onClick={()=>setShowSecondStep(1)}>
                                                <div className={showSecondStep===1 ? 'new_site_ribbon active' : 'new_site_ribbon'}>Boarding Point</div>
                                            </Link>
                                        </div>
                                        {
                                            bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['journey_details'] && bookingInfoDtls[props.busInfo.bus_id]['journey_details']['boarding_point'] !=='' ?
                                                <div className='second_step'>
                                                    <Link onClick={()=>setShowSecondStep(2)}>
                                                        <div className={showSecondStep===2 ? 'new_site_ribbon active' : 'new_site_ribbon' }>Dropping Point</div>
                                                    </Link>
                                                </div> 
                                            :
                                            <div className={blink===2 ? 'blink_div second_step' : 'second_step'} style={allSeatDetails.boardingPoint!='' ? {display : ''} : {display : 'none'}}>
                                                <Link onClick={()=>setShowSecondStep(2)}>
                                                    <div className={showSecondStep===2 ? 'new_site_ribbon active' : 'new_site_ribbon' }>Dropping Point</div>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                    <div className="after_selected_seat_sec">
                                        <div className="select_points" style={showSecondStep===1 ? {display : ''} : {display : 'none'}}>
                                            {boardingPoints.length > 0 && renderBoardingPoints()}
                                        </div>
                                        <div className="select_points" style={showSecondStep===2 ? {display : ''} : {display : 'none'}}>
                                            {droppingPoints.length > 0 && renderDroppingPoints()}
                                        </div>
                                    </div>
                                    {
                                        bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['journey_details'] && bookingInfoDtls[props.busInfo.bus_id]['journey_details']['dropping_point'] !='' ? 
                                            <div className='select_points book_now_sec'>
                                                <button type="button" className="btn btn-warning btn-block bookNow" onClick={()=>setShowSecondStep(3)}>BOOK NOW</button>
                                            </div>
                                        :
                                            showSecondStep==2 && (selectedSeatString!='' || childState.length>0) ?
                                                <div className='select_points book_now_sec' style={allSeatDetails.droppingPoint!='' ? {display : ''} : {display : 'none'}}>
                                                    <button type="button" className="btn btn-warning btn-block bookNow" onClick={()=>setShowSecondStep(3)}>BOOK NOW</button>
                                                </div> 
                                            :
                                            ''
                                    }
                                    <div className='view_more_bus_list'>
                                        <span className='view_more_bus_list_btn' onClick={() => { closeBusViewDetails(); props.setSelectedBusId(null);props.setBlogShowStatus(true) }}>View More Bus(es)</span>
                                        <div className="viewmore_icon">
                                            <FontAwesomeIcon icon={faAngleDoubleDown} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !isMobile && 
                            <div className='after_book_seat' style={showSecondStep==3 ? {display:''} : {display:'none'}}>
                                <div className='selected_seats'>
                                    <label htmlFor="">Seats No. </label>
                                    <div className='seat_number_div'>
                                        {selectedSeatString}
                                    </div>
                                    <div className='seat_layout_close'><FontAwesomeIcon icon={faTimes} onClick={closeBusViewDetails}/></div>
                                </div>
                                <div className='passanger_info_details'>
                                    <form id="passanger_info_form" ref={formRef} name="passanger_info_form" onSubmit={(event)=>{proceedToPreview(event)}}>
                                        <div className='upper_div'>
                                            <h4 className='h4_label_cls'><FontAwesomeIcon icon={faUser} /> Passanger Details</h4>
                                        </div>
                                        <div className='passenger_field_div'>
                                            {
                                                bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['seats'] ?
                                                    JSON.parse(localStorage.getItem('bookingDetails'))[props.busInfo.bus_id]['seats']?.map((seat, index) => (
                                                        <div className='individual_passanger_info' key={index}>
                                                            <input type="text" className='y_form_control' value={seat.seatName} placeholder='Seat Name' readOnly={true} />
                                                            <input 
                                                                type="text" 
                                                                className='y_form_control' 
                                                                placeholder='Enter Name' 
                                                                name={`passanger_${seat.seatName}_name`} 
                                                                defaultValue={
                                                                    bookingInfoDtls[props.busInfo.bus_id]['passanger']
                                                                    ? bookingInfoDtls[props.busInfo.bus_id].passanger[
                                                                        `passanger_${seat.seatName}_name`
                                                                        ]
                                                                    : ''
                                                                }
                                                                required
                                                            />
                                                            <input 
                                                                type="number" 
                                                                className='y_form_control' 
                                                                placeholder='Enter Age' 
                                                                name={`passanger_${seat.seatName}_age`}
                                                                defaultValue={
                                                                    bookingInfoDtls[props.busInfo.bus_id]['passanger']
                                                                    ? bookingInfoDtls[props.busInfo.bus_id].passanger[
                                                                        `passanger_${seat.seatName}_age`
                                                                        ]
                                                                    : ''
                                                                }
                                                                required
                                                            />
                                                            {
                                                                seat.seatFor === '2' ? 
                                                                <select type="text" className='y_form_control' name={`passanger_${seat.seatName}_gender`} defaultValue="1" required>
                                                                    <option value="">Select Gender</option>
                                                                    <option value="1">Female</option>
                                                                </select>
                                                                :
                                                                <select 
                                                                    type="text" 
                                                                    className='y_form_control' 
                                                                    name={`passanger_${seat.seatName}_gender`} 
                                                                    defaultValue={
                                                                        bookingInfoDtls[props.busInfo.bus_id]['passanger']
                                                                        ? bookingInfoDtls[props.busInfo.bus_id].passanger[
                                                                            `passanger_${seat.seatName}_gender`
                                                                            ]
                                                                        : ''
                                                                    }
                                                                    required
                                                                >
                                                                    <option value="">Select Gender</option>
                                                                    <option value="0">Male</option>
                                                                    <option value="1">Female</option>
                                                                    <option value="2">Other</option>
                                                                </select>
                                                            }
                                                        </div>
                                                    ))
                                                : ''
                                            }
                                        </div>
                                        <div className='upper_div'>
                                            <h4 className='h4_label_cls'><FontAwesomeIcon icon={faEnvelope} /> Contact Details </h4><label className='contact_info'><FontAwesomeIcon icon={faInfoCircle} /> Your ticket will be sent to these details</label>
                                        </div>
                                        <div className='passenger_field_div'>
                                            <div className='contact_details_info'>
                                                <select className='y_form_control' name='countryCode' defaultValue='91'>
                                                    <option value="91">+91</option>
                                                </select>
                                                <input 
                                                    type="text" 
                                                    className='y_form_control' 
                                                    placeholder='Phone Number' 
                                                    name="phoneNumber" 
                                                    defaultValue={bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['passanger'] ? bookingInfoDtls[props.busInfo.bus_id].passanger.phoneNumber : ''} 
                                                    required 
                                                />
                                                <input 
                                                    type="text" 
                                                    className='y_form_control' 
                                                    placeholder='Email ID' 
                                                    name="emailId" 
                                                    defaultValue={bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['passanger'] ? bookingInfoDtls[props.busInfo.bus_id].passanger.emailId : ''}  
                                                    required 
                                                />
                                                {/* <input 
                                                    type="text" 
                                                    className='y_form_control' 
                                                    placeholder='Full Name' 
                                                    name="fullName" 
                                                    defaultValue={bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['passanger'] ? bookingInfoDtls[props.busInfo.bus_id].passanger.fullName : ''}
                                                /> */}
                                            </div>
                                            {
                                                phoneNumberValidationError && <span className="error" style={{color:'red'}}>Please enter a valid phone number</span>
                                            }
                                        </div>
                                        <div className='after_passenger_field_div'>
                                            <button type="button" className="btn btn-warning back_btn submit_btn" onClick={()=>setShowSecondStep(2)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                                            {/* <button type="submit" className="btn submit_btn" onClick={(event)=>{proceedToPreview(event)}}>Procced To Payment</button> */}
                                            <button type="submit" className="btn submit_btn">Procced To Payment</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </ViewSeatDiv>
                </div>
            }
            {
                showModal && (
                    <Modal 
                    isOpen={props.showViewSeat===true ? true : false}
                    onRequestClose={()=>{document.body.classList.remove('body-no-scroll');}}
                    contentLabel="Example Modal"
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                    >
                        <div className='modal_inner_sec_mob'>
                            <div className="modal_main_sec_inner blue_theme_head">
                                <div className="modal_main_sec_inner_body">
                                    <div className='modal_main_sec_inner_body_head'>
                                        <div className='head-right-site'>
                                            <button type="button" className="close" data-dismiss="modal" onClick={()=>{document.body.classList.remove('body-no-scroll');setShowModal(false);closeBusViewDetails(); props.setSelectedBusId(null);props.setBlogShowStatus(true)}}>
                                                <img src={process.env.PUBLIC_URL + '/images/Close_red_btn.svg'} alt="Yesbus" />
                                            </button>
                                        </div>
                                        <div className='head-left-site'>
                                            <div className='bus_name_sec'>{props.bus_name}</div>
                                            <div className='bus_from_to'>
                                                <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(props.source) }} />

                                                <FontAwesomeIcon icon={faArrowRightLong} />
                                                <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(props.destination) }} />
                                            </div>
                                            <div className='bus_doj'>{Helper.formatDate(props.doj)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='seat_layout_mob_seat_view'>
                                <div className="seat_layout_head row">
                                    <div className={selectedSeatType==1 ? 'col-6 seat_type_label seat_type_seater active' : 'col-6 seat_type_label seat_type_seater'} onClick={()=>{setSelectedSeatType(1)}}>Lower</div>
                                    <div className={selectedSeatType==2 ? 'col-6 seat_type_label seat_type_sleeper active' : 'col-6 seat_type_label seat_type_sleeper'} onClick={()=>{setSelectedSeatType(2)}}>Upper</div>
                                </div>
                                <div className='seat_layout_body'>
                                    <ViewSeatDiv className='view_seat_layouts' style={props.showViewSeat===true ? {display:''} : {display:'none'}}>
                                        {
                                            props.seatLayoutsLoading===true ?
                                            <div className='before_book_seat'>
                                                <h5>Loading ......</h5>
                                            </div>
                                            :
                                            <div className='before_book_seat' style={showSecondStep===3 ? {display:'none'} : {display:''}}>
                                                <div className='dotted_border' style={continueClicked===true ? {display:'none'} : {display:''}}>
                                                    <div className='seat_layouts_div'>
                                                        {/* {
                                                            selectedSeatType==1 && (props.busInfo.lower_left_mastrix!='' || props.busInfo.lower_right_mastrix!='') ?
                                                            <div className='seat_layouts_lower_div'>
                                                                <div className="layout1_mob">
                                                                    <div className="layout-bg">
                                                                        <ol className="cabin fuselage left_side">
                                                                            
                                                                        </ol>
                                                                        <ol className="cabin fuselage">
                                                                            
                                                                        </ol>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            ''
                                                        } */}
                                                        <InfinitySeatLayoutMob
                                                            ITSSeatDetails={props.ITSSeatDetails} 
                                                            bus_id={props.busInfo.bus_id}
                                                            selectedSeatType={selectedSeatType}
                                                            onStateChange={handleChildStateChange}
                                                        />
                                                    </div>
                                                    
                                                    <div className='no_of_seat_div'>
                                                        {/* <h5>49 Seats</h5> */}
                                                        <div className='seat_color_div'>
                                                            <div className='color_div_upper'>
                                                                <span>Female</span>
                                                                <div className='color_div bg-free-pink'></div>
                                                            </div>
                                                            <div className='color_div_upper'>
                                                                <span>Available</span>
                                                                <div className='color_div bg-free-blue'></div>
                                                            </div>
                                                            <div className='color_div_upper'>
                                                                <span>Unavailable</span>
                                                                <div className='color_div bg-gray'></div>
                                                            </div>
                                                            <div className='color_div_upper'>
                                                                <span>Selected</span>
                                                                <div className='color_div bg-blue'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] 
                                                    && bookingInfoDtls[props.busInfo.bus_id]['seats']
                                                    && bookingInfoDtls[props.busInfo.bus_id]['seats'].length>0 && continueClicked===true 
                                                    ?
                                                    (
                                                        <div className='dotted_border_right_sec'>
                                                            <div className='seat_layout_close' onClick={() => {setContinueClicked(false)}}><FontAwesomeIcon icon={faArrowLeftLong}/> Back </div>
                                                            <div className='form_all_steps'>
                                                                <div className={bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && blink==0  ? 'first_step blink_div' : 'first_step'}>
                                                                    <Link onClick={()=>setShowSecondStep(1)}>
                                                                        <div className={showSecondStep==1 ? 'new_site_ribbon active' : 'new_site_ribbon'}>Boarding Point</div>
                                                                    </Link>
                                                                </div>
                                                                {
                                                                    bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['journey_details'] && bookingInfoDtls[props.busInfo.bus_id]['journey_details']['boarding_point'] !='' ?
                                                                        <div className='second_step'>
                                                                            <Link onClick={()=>setShowSecondStep(2)}>
                                                                                <div className={showSecondStep==2 ? 'new_site_ribbon active' : 'new_site_ribbon' }>Dropping Point</div>
                                                                            </Link>
                                                                        </div> 
                                                                    :
                                                                    <div className={blink==2 ? 'blink_div second_step' : 'second_step'} style={allSeatDetails.boardingPoint!='' ? {display : ''} : {display : 'none'}}>
                                                                        <Link onClick={()=>setShowSecondStep(2)}>
                                                                            <div className={showSecondStep==2 ? 'new_site_ribbon active' : 'new_site_ribbon' }>Dropping Point</div>
                                                                        </Link>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className='after_selected_seat_sec'>
                                                                <div className='select_points' style={showSecondStep===1 ? {display : ''} : {display : 'none'}}>
                                                                    {boardingPoints.length > 0 && renderBoardingPoints()}
                                                                </div>
                                                                <div className='select_points' style={showSecondStep===2 ? {display : ''} : {display : 'none'}}>
                                                                    {droppingPoints.length > 0 && renderDroppingPoints()}
                                                                </div>
                                                                
                                                            </div>
                                                            {
                                                                bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['journey_details'] && bookingInfoDtls[props.busInfo.bus_id]['journey_details']['dropping_point'] !=='' ? 
                                                                    <div className='select_points book_now_sec'>
                                                                        <button type="button" className="btn btn-warning btn-block bookNow" onClick={()=>setShowSecondStep(3)}>BOOK NOW</button>
                                                                    </div>
                                                                :
                                                                    showSecondStep===2 && (selectedSeatString!=='' || childState.length>0) ?
                                                                        <div className='select_points book_now_sec' style={allSeatDetails.droppingPoint!='' ? {display : ''} : {display : 'none'}}>
                                                                            <button type="button" className="btn btn-warning btn-block bookNow" onClick={()=>setShowSecondStep(3)}>BOOK NOW</button>
                                                                        </div> 
                                                                    :
                                                                    ''
                                                            }
                                                            <div className='view_more_bus_list'>
                                                                <span className='view_more_bus_list_btn' onClick={() => {document.body.classList.remove('body-no-scroll');closeBusViewDetails(); props.setSelectedBusId(null);props.setBlogShowStatus(true) }}>View More Bus(es)</span>
                                                                <div className="viewmore_icon">
                                                                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                        </div>
                                                    )
                                                    :
                                                    ''
                                                }
                                            </div>
                                        }
                                        {
                                            isMobile && 
                                            <div className='before_book_seat after_book_seat' style={showSecondStep===3 ? {display:''} : {display:'none'}}>
                                                <div className='selected_seats'>
                                                    <label htmlFor="">Seats No. </label>
                                                    <div className='seat_number_div'>
                                                        {selectedSeatString}
                                                    </div>
                                                    <div className='seat_layout_close'><FontAwesomeIcon icon={faTimes} onClick={()=>{document.body.classList.remove('body-no-scroll');setShowModal(false);closeBusViewDetails(); props.setSelectedBusId(null);props.setBlogShowStatus(true)}}/></div>
                                                </div>
                                                <div className='passanger_info_details'>
                                                    <form id="passanger_info_form" ref={formRef} name="passanger_info_form" onSubmit={(event)=>{proceedToPreview(event)}}>
                                                        <div className='upper_div'>
                                                            <h4 className='h4_label_cls'><FontAwesomeIcon icon={faUser} /> Passanger Details</h4>
                                                        </div>
                                                        <div className='passenger_field_div'>
                                                            {
                                                                bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['seats'] ?
                                                                    JSON.parse(localStorage.getItem('bookingDetails'))[props.busInfo.bus_id]['seats']?.map((seat, index) => (
                                                                        <div className='individual_passanger_info' key={index}>
                                                                            <input type="text" className='y_form_control' value={'Seat : '+seat.seatName} placeholder='Seat Name' readOnly={true} />
                                                                            <input 
                                                                                type="text" 
                                                                                className='y_form_control' 
                                                                                placeholder='Enter Name' 
                                                                                name={`passanger_${seat.seatName}_name`} 
                                                                                defaultValue={
                                                                                    bookingInfoDtls[props.busInfo.bus_id]['passanger']
                                                                                    ? bookingInfoDtls[props.busInfo.bus_id].passanger[
                                                                                        `passanger_${seat.seatName}_name`
                                                                                        ]
                                                                                    : ''
                                                                                }
                                                                                required
                                                                            />
                                                                            <input 
                                                                                type="number" 
                                                                                className='y_form_control' 
                                                                                placeholder='Enter Age' 
                                                                                name={`passanger_${seat.seatName}_age`}
                                                                                defaultValue={
                                                                                    bookingInfoDtls[props.busInfo.bus_id]['passanger']
                                                                                    ? bookingInfoDtls[props.busInfo.bus_id].passanger[
                                                                                        `passanger_${seat.seatName}_age`
                                                                                        ]
                                                                                    : ''
                                                                                }
                                                                                required
                                                                            />
                                                                            {
                                                                                seat.seatFor === '2' ? 
                                                                                <select type="text" className='y_form_control' name={`passanger_${seat.seatName}_gender`} defaultValue="1" required>
                                                                                    <option value="">Select Gender</option>
                                                                                    <option value="1">Female</option>
                                                                                </select>
                                                                                :
                                                                                <select 
                                                                                    type="text" 
                                                                                    className='y_form_control' 
                                                                                    name={`passanger_${seat.seatName}_gender`} 
                                                                                    defaultValue={
                                                                                        bookingInfoDtls[props.busInfo.bus_id]['passanger']
                                                                                        ? bookingInfoDtls[props.busInfo.bus_id].passanger[
                                                                                            `passanger_${seat.seatName}_gender`
                                                                                            ]
                                                                                        : ''
                                                                                    }
                                                                                    required
                                                                                >
                                                                                    <option value="">Select Gender</option>
                                                                                    <option value="0">Male</option>
                                                                                    <option value="1">Female</option>
                                                                                    <option value="2">Other</option>
                                                                                </select>
                                                                            }
                                                                        </div>
                                                                    ))
                                                                : ''
                                                            }
                                                        </div>
                                                        <div className='upper_div'>
                                                            <h4 className='h4_label_cls'><FontAwesomeIcon icon={faEnvelope} /> Contact Details </h4><label className='contact_info'><FontAwesomeIcon icon={faInfoCircle} /> Your ticket will be sent to these details</label>
                                                        </div>
                                                        <div className='passenger_field_div'>
                                                            <div className='contact_details_info'>
                                                                <select className='y_form_control' name='countryCode' defaultValue='91' style={{display:'none'}}>
                                                                    <option value="91">+91</option>
                                                                </select>
                                                                <input 
                                                                    type="text" 
                                                                    className='y_form_control' 
                                                                    placeholder='Phone Number' 
                                                                    name="phoneNumber" 
                                                                    defaultValue={bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['passanger'] ? bookingInfoDtls[props.busInfo.bus_id].passanger.phoneNumber : ''} 
                                                                    required 
                                                                />
                                                                
                                                                <input 
                                                                    type="text" 
                                                                    className='y_form_control' 
                                                                    placeholder='Email ID' 
                                                                    name="emailId" 
                                                                    defaultValue={bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['passanger'] ? bookingInfoDtls[props.busInfo.bus_id].passanger.emailId : ''}  
                                                                    required 
                                                                />
                                                                {/* <input 
                                                                    type="text" 
                                                                    className='y_form_control' 
                                                                    placeholder='Full Name' 
                                                                    name="fullName" 
                                                                    defaultValue={bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] && bookingInfoDtls[props.busInfo.bus_id]['passanger'] ? bookingInfoDtls[props.busInfo.bus_id].passanger.fullName : ''}
                                                                /> */}
                                                            </div>
                                                            {
                                                                phoneNumberValidationError && <span className="error" style={{color:'red'}}>Please enter a valid phone number</span>
                                                            }
                                                        </div>
                                                        <div className='after_passenger_field_div'>
                                                            <button type="button" className="btn btn-warning back_btn submit_btn" onClick={()=>setShowSecondStep(2)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                                                            <button type="submit" className="btn submit_btn">Procced To Payment</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        }
                                    </ViewSeatDiv>
                                </div>
                            </div>
                        </div>
                        {
                            bookingInfoDtls && bookingInfoDtls[props.busInfo.bus_id] 
                            && bookingInfoDtls[props.busInfo.bus_id]['seats']
                            && bookingInfoDtls[props.busInfo.bus_id]['seats'].length>0 && continueClicked===false 
                            ?
                            (
                                <div className='seat_layout_footer'>
                                    <div onClick={() => { closeBusViewDetails(); props.setSelectedBusId(null);props.setBlogShowStatus(true);document.body.classList.remove('body-no-scroll'); }}><FontAwesomeIcon icon={faArrowLeftLong} /> Back</div>
                                    <div onClick={()=>{setContinueClicked(true)}}>Continue <FontAwesomeIcon icon={faArrowRightLong}/></div>
                                </div>
                            )
                            :
                            ''
                        }
                    </Modal>
                )
            }
        </>
    )
}
const customStyles = {
    overlay: {
        backgroundColor: 'rgb(2 2 5 / 59%)', // Adjust the color and opacity as needed
        zIndex: '9999',
    },
    content: {
      top: '50%',          // Center the modal vertically
      left: '50%',         // Center the modal horizontally
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%', // Adjust based on modal width
      transform: 'translate(-50%, -50%)', // Center the modal both horizontally and vertically
      width: '101%',        // Adjust the modal width as needed
      backgroundColor: 'white',
      border: '1px solid #ccc',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      padding: '0px',
      overflow: 'inherit',
      height: '100vh'
    },
};
const ViewSeatDiv = styled.div`
    .after_book_seat{
        padding: 10px;
        background: #eeededc4;
        .seat_layout_close{
            text-align: end;
            font-size: 20px;
        }
        .selected_seats{
            display: grid;
            grid-template-columns: 0.5fr 3fr 1fr;
            gap: 10px;
            label{
                font-weight: 600;
                color: gray;
            }
            .seat_number_div{
                font-weight: 600;
                color: rgb(217, 57, 50);
            }
        }
        .passanger_info_details{
            padding-top: 15px;
            .h4_label_cls{
                font-size: 18px;
                color: #2f317e;
                font-weight: 700;
                text-transform: capitalize;
                letter-spacing: 1px;
                text-underline-position: under;
                text-decoration-line: underline;
                svg{
                    background: #d73932;
                    padding: 6px;
                    border-radius: 25px;
                    color: #fff;
                    vertical-align: middle;
                }
            }
            .passenger_field_div{
                padding: 20px 0px;
                .individual_passanger_info{
                    display: flex;
                    gap: 15px;
                    padding-bottom: 10px;
                    .y_form_control{
                        border: 1px solid silver;
                        border-radius: 4px;
                        height: 35px;
                        font-size: 15px;
                        padding: 5px 10px;
                    }
                    .y_form_control:nth-child(1):read-only{
                        background: silver;
                        color: #fff;
                        font-weight: 700;
                    }
                    .y_form_control:nth-child(1){
                        width: 100px;
                    }
                }
            }
            .upper_div{
                display: flex;
                gap: 15px;
                align-items: baseline;
                .contact_info{
                    background: #ffcf76;
                    color: #000000a3;
                    border-radius: 10px;
                    padding: 2px 10px;
                    font-size: 12px;
                    text-align: center;
                    font-weight: 600;
                    
                }
            }
            .contact_details_info{
                display: flex;
                gap: 15px;
                padding-bottom: 10px;
                .y_form_control{
                    border: 1px solid silver;
                    border-radius: 4px;
                    height: 35px;
                    font-size: 15px;
                    padding: 5px 10px;
                }
            }
            .after_passenger_field_div{
                text-align:right;
                .submit_btn{
                    font-size: 15px;
                    font-weight: 600;
                    background: #2f317e;
                    color: #fff;
                    border: none;
                    padding: 5px 10px;
                    margin-right: 15px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                .back_btn{
                    background: gray;
                }
            }
        }
        
        
    }
    .before_book_seat{
        padding: 10px;
        background: #eeededc4;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 15px;
        .dotted_border{
            padding: 10px;
            border: 2px dashed #2f3183;
            margin: 0px;
            .no_of_seat_div{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                h5{
                    font-size: 15px;
                    color: #2f3183;
                    margin-bottom: 0px;
                }
                .seat_color_div{
                    display: flex;
                    gap: 10px;
                    .color_div_upper{
                        display: flex;
                        gap: 5px;
                        align-items: self-end;
                        justify-content: center;
                        span{
                            font-weight: 500;
                            text-transform: capitalize;
                        }
                    }
                    .color_div{
                        width: 17px;
                        height: 17px;
                        border-radius: 4px;
                    }
                    .bg-free-blue{
                        background: #fff;
                        border: 2px solid rgb(0, 0, 122);
                    }
                    .bg-free-pink{
                        background: #fff;
                        border: 2px solid rgb(243 174 250);
                    }
                    .bg-gray{
                        background: #b2b2b2;
                    }
                    .bg-blue{
                        background: rgb(0 0 122);
                    }
                }
            }
            
            span{
            font-size: 13px;
            color: gray;
            }
            .seat_layouts_div{
                .seat_layout_label{
                    margin: 5px 0px;
                    color: gray;
                }
                .seat_layouts_upper_div{
                    background: #fff;
                    margin-bottom: 10px;
                }
                .layout1{
                    background-color: #fff;
                    padding: 20px 0px 14px 0px;
                    position: relative;
                    margin-bottom: 15px;
                    margin-left: 25px;
                    margin-right: 25px;
                    border-radius: 8px;
                    .driver-seat{
                        width: 40px;
                        height: 40px;
                        margin-right: 5px;
                        background-position: -24px -2px;
                        background-image: url(../../images/seats-sprite-new.png);
                    }
                    .layout-bg{
                        padding-left: 0px;
                        .fuselage {
                            border-right: none;
                            border-left: none;
                            position: relative;
                            display: flow-root;
                            padding-left: 0px;
                            ol {
                                list-style: none;
                                padding-left: 6px;
                                margin: 0;
                                ${'' /* display: flex;
                                flex-flow: row-reverse; */}
                                li{
                                    .seatImageDiv{
                                        min-width: 40px;
                                    }
                                }
                            }
                            ol.mobile_view_seat_ol{
                                display: flex;
                                flex-flow: row-reverse;
                                justify-content: center;
                                transform: rotate(180deg);
                                align-items: flex-end;
                                .empty-seat{
                                    padding: 0px 15px;
                                }
                            }
                            ol.seats.row{
                                transform: rotate(180deg);
                                li.seat.lower-berth{
                                    .seatImageDiv{
                                        label{
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                            }
                            .seats{
                            display: flex;
                            justify-content: space-between;
                            .driver-sleeper-img{
                                position: absolute;
                                top: 0;
                                right: 100%;
                            }
                            .sleeper.rotate-90{
                                margin: 0px 10px;
                            }
                            .sleeper{
                                ${'' /* display: flex; */}
                                display: grid;
                                padding: 0 3px;
                                position: relative;
                                .hide{
                                    display: block;
                                    position: relative;
                                    width: 100%;
                                    text-align: center;
                                    font-size: 14px;
                                    font-weight: 700;
                                    color: #fff;
                                    ${'' /* padding: 11px 25.2px; */}
                                    width: 40px;
                                    background: #0000;
                                    height: 20px;
                                    background-repeat: no-repeat;
                                    animation-duration: .3s;
                                    animation-fill-mode: both;
                                }
                                input[type=checkbox]{
                                    position: absolute;
                                    opacity: 0;
                                }
                                .sleeper-free-blue {
                                    text-indent: -56%;
                                    overflow: hidden;
                                    padding: 9px 14px 6px 25px;
                                    font-size: 12px;
                                    background-image: url(../../images/sleeper-free-blue.svg);
                                    cursor: pointer;
                                    color: rgb(0, 0, 122);
                                }
                                .sleeper-free-pink {
                                    text-indent: -56%;
                                    overflow: hidden;
                                    padding: 9px 14px 6px 25px;
                                    font-size: 12px;
                                    background-image: url(../../images/sleeper-free-pink.svg);
                                    cursor: pointer;
                                    color: rgb(243, 174, 250);
                                }
                                .sleeper-free-pink.selected {
                                    background-image: url(../../images/sleeper-pink.svg);
                                    color: rgb(243, 174, 250);
                                    color: #fff;
                                }
                                .sleeper-free-pink.booked {
                                    background-image: url(../../images/sleeper-pink.svg);
                                    color: rgb(243, 174, 250);
                                    cursor: context-menu;
                                    color: #fff;
                                }
                                .sleeper-free-blue.selected {
                                    background-image: url(../../images/sleeper-blue.svg);
                                    color: #fff;
                                }
                                label{
                                text-indent: -56%;
                                display: block;
                                position: relative;
                                width: 64px;
                                height: 34px;
                                text-align: center;
                                font-size: 12px;
                                font-weight: 700;
                                color: #fff;
                                padding: 8px 14px 13px 25px;
                                background-repeat: no-repeat;
                                animation-duration: .3s;
                                animation-fill-mode: both;
                                background-image: url(../../images/sleeper-gray.svg);
                                background-position: 100%;
                                }
                                label.rotate-90{
                                    transform: rotate(90deg) !important;
                                    margin: 8px 0px 3px;
                                    ${'' /* padding: 7px 0px 7px 16px; */}
                                }
                                label:before {
                                content: "";
                                position: absolute;
                                width: 96%;
                                height: 95%;
                                top: 1px;
                                left: 50%;
                                transform: translate(-50%);
                                background: #0000;
                                border-radius: 3px;
                                }
                            }
                            }
                        }
                        .fuselage.mobile_view_seat_ol{
                            li{
                                gap : 7px !important
                            }
                            .mobile_view_seat_ol{
                                li.seat{
                                    label{
                                        transform: rotate(-90deg);
                                        padding: 10px 0px;
                                        p{
                                            transform: rotate(270deg);
                                        }
                                    }
                                }
                            }
                        }
                        .left_side{
                            padding-top: 30px;
                            width: 100%;
                        }
                    }
                }
            .layout1{
                background-color: #fff;
                padding: 20px 0px 14px 0px;
                position: relative;
                margin-left: 25px;
                margin-right: 25px;
                border-radius: 8px;
                .driver-seat{
                    width: 35px;
                    height: 41px;
                    margin-right: 5px;
                    background-position: -24px -2px;
                    background-image: url(../../images/seats-sprite-new.png);
                }
                .layout-bg{
                padding-left: 10px;
                ol{
                    list-style: none;
                    padding-left: 0px;
                    margin: 0;
                    li.row{
                    margin-bottom: 3px;
                    }
                }
                .fuselage{
                    border-right: none;
                    border-left: none;
                    position: relative;
                    display: flow-root;
                    .driver-seat-img{
                    position: absolute;
                    top: 0;
                    right: 100%;
                    }
                    .seats {
                    display: flex;
                    ${'' /* flex-direction: row; */}
                    ${'' /* flex-wrap: nowrap; */}
                    ${'' /* justify-content: space-evenly; */}
                    justify-content: space-between;;
                    .seat{
                        ${'' /* display: flex; */}
                        padding: 0 2px;
                        position: relative;
                        .hide{
                            display: block;
                            position: relative;
                            width: 100%;
                            text-align: center;
                            font-size: 13px;
                            font-weight: 700;
                            color: #fff;
                            background: #0000;
                            background-repeat: no-repeat;
                            animation-duration: .3s;
                            animation-fill-mode: both;
                            ${'' /* padding: 8px 23.2px; */}
                            width: 35px;
                        }
                    }
                    input[type=checkbox] {
                        position: absolute;
                        opacity: 0;
                    }
                    label.lable-gray.fixsize {
                        background-image: url(../../images/seat-gray.svg);
                    }
                    ${'' /* label.lable-pink.fixsize {
                        background-image: url(../../images/seat-pink.svg);
                    } */}
                    input[type=checkbox] + .seat-free-blue {
                        overflow: hidden;
                        font-size: 7px;
                        background-image: url(../../images/seat-free-blue.svg);
                        cursor: pointer;
                        color : rgb(0, 0, 122)
                        ${'' /* transform: rotate(270deg); */}
                    }
                    input[type=checkbox] + .seat-free-blue.selected {
                        background-image: url(../../images/seat-blue.svg);
                        color : #fff
                    }
                    input[type=checkbox] + .seat-free-blue.booked {
                        background-image: url(../../images/seat-gray.svg);
                        cursor: context-menu;
                        color : #fff
                    }
                    input[type=checkbox] + .seat-free-gray.booked {
                        background-image: url(../../images/seat-gray.svg);
                        cursor: context-menu;
                        color : #fff
                    }
                    input[type=checkbox] + .seat-free-pink {
                        overflow: hidden;
                        font-size: 9px;
                        background-image: url(../../images/seat-free-pink.svg);
                        cursor: pointer;
                        color: #f3aefa;
                    }
                    input[type=checkbox] + .seat-free-pink.selected {
                        background-image: url(../../images/seat-pink.svg);
                        color: #fff;
                    }

                    input[type=checkbox] + .seat-free-pink.booked {
                        background-image: url(../../images/seat-pink.svg);
                        cursor: context-menu;
                        color: #fff;
                    }
                    
                    ${'' /* input[type=checkbox] + .seat-blue {
                        overflow: hidden;
                        font-size: 9px;
                        background-image: url(../../images/seat-blue.svg);
                        cursor: pointer;
                    } */}
                    label{
                        text-indent: -40%;
                        display: block;
                        position: relative;
                        width: 100%;
                        text-align: center;
                        font-size: 9px;
                        font-weight: 800;
                        color: #fff;
                        padding: 13px 9px;
                        background-repeat: no-repeat;
                        animation-duration: .3s;
                        animation-fill-mode: both;
                        transform: rotate(180deg);
                    }
                    .fixsize{
                        ${'' /* width: 28.5px!important;
                        height: 28.5px!important; */}
                        width: 34.5px !important;
                        height: 40.5px !important;
                    }
                    label:before {
                        content: "";
                        position: absolute;
                        width: 75%;
                        height: 75%;
                        top: 1px;
                        left: 50%;
                        transform: translate(-50%);
                        background: #0000;
                        border-radius: 3px;
                    }
                    input[type=checkbox]:disabled + label:after {
                        content: "";
                        text-indent: -40%;
                        position: absolute;
                        top: 4px;
                        left: 50%;
                        transform: translate(-50%);
                    }
                    }
                }
                .left_side{
                    padding-top: 30px;
                    width: 100%;
                }
                }
            }
            .seat_layouts_lower_div{
                background: #fff;
            }
            }
        }
        .dotted_border_right_sec{
            .after_selected_seat_sec{
                background: #fff;
                border-radius: 7px;
                padding: 20px;
                height: 300px;
                overflow-y: auto;
            }
            .after_selected_seat_sec::-webkit-scrollbar {
                width:5px;
            }
            .after_selected_seat_sec::-webkit-scrollbar-track {
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); 
                border-radius:5px;
            }
            .after_selected_seat_sec::-webkit-scrollbar-thumb {
                border-radius:5px;
                -webkit-box-shadow: inset 0 0 6px rgb(47, 49, 126); 
            }
            .form_all_steps{
                .blink_div{
                    .new_site_ribbon{
                        animation: blinker 1s linear infinite;
                    }
                    @keyframes blinker {
                        50% {
                            ${'' /* opacity: 0; */}
                            color:rgb(217, 57, 50);
                        }
                    }
                }
                ${'' /* display: flex;
                justify-content: space-evenly;
                flex-direction: row;
                align-items: center;
                grid-template-columns: 2fr 1fr; */}
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                margin-left: 10px;
                margin-bottom: 39px;
                .new_site_ribbon{
                    position: absolute;
                    height: 24px;
                    min-width: 75px;
                    ${'' /* background: rgb(217, 57, 50); */}
                    background: rgb(178 178 178);
                    padding: 0px 5px;
                    color: #fff;
                    font-size: 13px;
                }
                .new_site_ribbon:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-width: 12px 5px;
                    border-style: solid;
                    border-color: rgb(178 178 178) rgb(178 178 178) rgb(178 178 178) transparent;
                    position: absolute;
                    left: -9px;
                }
                .new_site_ribbon:after {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 20px solid rgb(178 178 178);
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    right: -20px;
                }
                .new_site_ribbon.active{
                    background: rgb(47 49 126);
                }
                .new_site_ribbon.active:before {
                    
                    border-color: rgb(47 49 126) rgb(47 49 126) rgb(47 49 126) transparent;
                }
                .new_site_ribbon.active:after {
                    border-left: 20px solid rgb(47 49 126);
                    
                }
            }
            .seat_layout_close{
                text-align: right;
                padding-bottom: 10px;
                svg{
                    font-size: 21px;
                    color: #2e3188;
                    cursor: pointer;
                }
            }
            .select_points{
            display: grid;
            grid-gap: 5px;
            color: gray;
            .points_inner_div{
                display: flex;
                gap: 15px;
                padding-bottom: 10px;
                input[type="radio"]{
                    width: 15px;
                }
                .point_label_time{
                    font-size: 15px;
                    font-weight: 700;
                    color: #2f317e;
                }
                .point_label_location{
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            ${'' /* padding: 0px 40px; */}
            ${'' /* select{
                margin-bottom: 20px;
                padding: 3px 5px;
                color: #2e3188;
                border: 1px solid;
                border-radius: 4px;
                font-size: 14px;
            } */}
            .selctd_seat_label{
                padding: 0px 0px 6px;
                border-bottom: 2px solid rgb(255, 255, 255);
            }
            .selected_seat_details{
                display: flex;
                grid-gap: 10px;
                justify-content: space-between;
                align-items: baseline;
                font-size: 16px;
                padding: 12px 0px;
                span{
                color: #2e3188;
                font-weight: 700;
                font-size: 16px;
                }
            }
            .selected_seat_fare_details{
                display: flex;
                grid-gap: 10px;
                justify-content: space-between;
                align-items: baseline;
                font-size: 16px;
                span{
                color: #2e3188;
                font-weight: 700;
                font-size: 16px;
                }
            }
            }
            .book_now_sec{
            margin-top: 30px;
            .bookNow{
                background: rgb(47, 49, 131);
                color: rgb(226, 122, 118);
                border-color: rgb(47, 49, 131);
                font-size: 18px;
                font-weight: 600;
            }
            .bookNow:hover{
                background: rgb(226, 122, 118);
                color: rgb(47, 49, 131);
                border-color: rgb(226, 122, 118);
            }
            }
            .view_more_bus_list{
                ${'' /* animation: vmoreiconblinker 1s linear infinite;
                @keyframes vmoreiconblinker {
                    50% {
                        opacity: 0;
                        color:rgb(217, 57, 50);
                    }
                } */}
                text-align: center;
                padding-top: 19px;
                .view_more_bus_list_btn{
                    color: rgb(217, 57, 50);
                    font-size: 20px;
                    font-weight: 700;
                    text-decoration: underline;
                    cursor: pointer;
                }
                
                .viewmore_icon{
                    animation: vmoreiconblinker 1s linear infinite;
                    @keyframes vmoreiconblinker {
                        50% {
                            opacity: 0;
                            color:rgb(217, 57, 50);
                        }
                    }
                    svg{
                        color: rgb(217, 57, 50);
                        font-size: 20px;
                    }
                }
            }
            
        }
    }

    @media (max-width: 767px) {
        .before_book_seat{
            display: flex;
            flex-direction: column;
        }
    }
    

    .empty-seat{
        padding:0px;
        height: 32px;
    }
    .empty-seat-space{
        padding:0px;
        height: 10px;
        width: 10px;
    }

    
`;
export default InfinityViewSeat