import React from 'react'
const InfinitySeatLayoutMob = (props) => {
    // console.log(props)
    const getSeatClassName = (seat) => {
        let className = '';
        if(seat.ColumnSpan=='2'){
          className += 'sleeper rotate-90 ';
        }else{
          if (seat.SeatType === 0) {
            className += 'seat ';
          } else if (seat.SeatType === 1) {
            className += 'sleeper ';
          } else if (seat.SeatType === 2) {
            className += 'slumber ';
          }
        }
        className += seat.Available === 'Y' ? 'available ' : 'booked ';
        className += seat.UpLowBerth === 'UB' ? 'upper-berth ' : 'lower-berth ';
        if (seat.Available === 'N' && seat.IsLadiesSeat === 'Y') {
            className += 'sleeper-free-pink booked ';
        } else if (seat.Available === 'N' && seat.IsLadiesSeat === 'N') {
            className += 'booked-by-male ';
        }
        return className.trim();
    };
    const getSeatChildClassName = (seat) => {
      let className = '';
      if(seat.IsLadiesSeat === 'Y'){
        className += seat.Available === 'Y' ? 'available ' : 'booked ';
        className+= seat.SeatType === 0 ? 'seat-free-pink ' : 'sleeper-free-pink ';
      } else if(seat.IsLadiesSeat === 'N'){
        className += seat.Available === 'Y' ? 'available ' : 'booked ';
        if(seat.Available === 'Y'){
          className+= seat.SeatType === 0 ? 'seat-free-blue' : 'sleeper-free-blue';
        }else{
          className+= seat.SeatType === 0 ? 'seat-free-gray' : 'sleeper-free-gray';
        }
      }
      return className.trim();
    };
    const handleSeatSelection = (seat) => {
      if(seat.Available === 'Y'){
        // document.getElementById(`seat_layout_${seat.SeatNo}`).classList.add('selected');
        let bookingInfo = JSON.parse(localStorage.getItem('bookingDetails'));
        // console.log(props.bus_id);
        if(bookingInfo){
          let seatAry = bookingInfo[props.bus_id]['seats'];
          // console.log(bookingInfo[props.bus_id]);
          const isSeatSelected = seatAry.some((bokingseat) => bokingseat.seatName === seat.SeatNo);
          // console.log(isSeatSelected);
          if (isSeatSelected) {
            document.getElementById(`seat_layout_${seat.SeatNo}`).classList.remove('selected');
            seatAry = seatAry.filter((bokingseat) => bokingseat.seatName !== seat.SeatNo);
          }else{
            if (seatAry.length >= 6) {
              alert('You can book maximum 6 seats');
              return false;
            }
            document.getElementById(`seat_layout_${seat.SeatNo}`).classList.add('selected');
            let seatName = seat.SeatNo
            let seatFor = seat.IsLadiesSeat==='N' ? '0' : '2';
            let seatFare = seat.SeatRate;
            let seatBaseFare = seat.BaseFare;
            let seatType = seat.SeatType===1 ? '1' : '0';
            seatAry = [...seatAry, {seatName, seatFor, seatFare ,seatBaseFare, seatType}];
          }
          bookingInfo[props.bus_id]['seats'] = seatAry;
          localStorage.setItem('bookingDetails', JSON.stringify(bookingInfo));  
        }else{
          document.getElementById(`seat_layout_${seat.SeatNo}`).classList.add('selected');
          let seatAry =  {
            seatName : seat.SeatNo,
            seatFor : seat.IsLadiesSeat==='N' ? '0' : '2',
            seatFare : seat.SeatRate,
            seatBaseFare : seat.BaseFare,
            seatType : seat.SeatType===1 ? '1' : '0'
          };
          let bookingInfo = {
            [props.bus_id]:{
              'referenceNum' : seat.ReferenceNumber,
              'seats' : [
                seatAry
              ]
            },
          };
          localStorage.setItem('bookingDetails',JSON.stringify(bookingInfo));
        }
        props.onStateChange(
          JSON.parse(localStorage.getItem('bookingDetails'))[props.bus_id]['seats'] || []
        );
      }
    };

    
    const generateSeatGrid = (seats) => {
      const seatGrid = [];
      const maxRow = Math.max(...seats.map(seat => seat.Row));
      const maxColumn = Math.max(...seats.map(seat => seat.Column));

      for (let row = 1; row <= maxRow; row++) {
          let cntr=0;
          const rowSeats = [];
          for (let col = 1; col <= maxColumn; col++) {
              const seat = seats.find(seat => seat.Row === row && seat.Column === col);

              if (seat) {
                if(seat.BlockType!=3){
                  cntr++
                  rowSeats.push(
                      <li key={seat.SeatNo} className={getSeatClassName(seat)+` row: ${row} column: ${col}`}>
                        <div className="">
                          <input type="checkbox"/>
                        </div>
                        <div className="seatImageDiv">
                          <input type="checkbox" id={`hideseat${seat.SeatNo}`}/>
                          {
                            seat.ColumnSpan=='2' ?
                            <label
                              className={`rotate-90 sleeper-free-${seat.Available === 'Y' ? 'blue' : 'gray'} ${seat.Available === 'Y' ? '' : 'booked'}`}
                              id={`seat_layout_${seat.SeatNo}`} 
                              title={`Seat No : ${seat.SeatNo} | Seat Fare : ${seat.SeatRate}  | Row : ${seat.Row}  | Column : ${seat.Column}`}
                              htmlFor={`upper${seat.SeatNo}`}
                              onClick={() => handleSeatSelection(seat)}
                            >
                              <p>₹{seat.SeatRate}</p>
                            </label>
                            :
                            <>
                              {/* <label
                                className={`${seat.SeatType===1 ? 'sleeper' : 'seat'}-free-${seat.Available === 'Y' ? 'blue' : 'gray'} ${seat.Available === 'Y' ? '' : 'booked'}`}
                                id={`seat_layout_${seat.SeatNo}`} 
                                title={`Seat No : ${seat.SeatNo} | Seat Fare : ${seat.SeatRate}  | Row : ${seat.Row}  | Column : ${seat.Column}`}
                                htmlFor={`upper${seat.SeatNo}`}
                                onClick={() => handleSeatSelection(seat)}
                              >
                                <p>₹{seat.SeatRate}</p>
                              </label> */}
                              <label
                                className={getSeatChildClassName(seat)}
                                id={`seat_layout_${seat.SeatNo}`} 
                                title={`Seat No : ${seat.SeatNo} | Seat Fare : ${seat.SeatRate}  | Row : ${seat.Row}  | Column : ${seat.Column}`}
                                htmlFor={`upper${seat.SeatNo}`}
                                onClick={() => handleSeatSelection(seat)}
                              >
                                <p>₹{seat.SeatRate}</p>
                              </label>
                            </>
                            
                          }
                        </div>
                      </li>
                  );
                }else{
                  if(seat.BlockType==3){
                    rowSeats.push(<li key={`${row}-${col}`} className="empty-seat"></li>);
                  }
                }
              }else {
                if(cntr>=1){
                  rowSeats.push(<li key={`${row}-${col}`} className="empty-seat-space"></li>);
                }
                
              }
          }
          // seatGrid.push(<ol className="seats row" key={`row-${row}`}>{rowSeats}</ol>);
          seatGrid.push(<ol className="mobile_view_seat_ol" key={`row-${row}`}>{rowSeats}</ol>);
      }
      return seatGrid;
  };

    const upperSeats = props.ITSSeatDetails.filter(seat => seat.UpLowBerth === 'UB');
    const lowerSeats = props.ITSSeatDetails.filter(seat => seat.UpLowBerth === 'LB');
    // console.log(props, upperSeats, lowerSeats)
    return (
        <div className="seat_layouts_div">
          <h5 style={{textAlign:'center',color:'gray'}}>Front</h5>
          <div className="seat_layouts_lower_div">
              <div className="layout1">
                  <div className="layout-bg">
                    {
                      props.selectedSeatType==1 ?
                        <ol className="cabin fuselage left_side mobile_view_seat_ol">
                          <li className="row seats">
                          {generateSeatGrid(lowerSeats)}
                          </li>
                        </ol>
                      :
                      <ol className="cabin fuselage left_side mobile_view_seat_ol">
                        <li className="row seats">
                        {generateSeatGrid(upperSeats)}
                        </li>
                      </ol>
                    }
                  </div>
              </div>
          </div>
        </div>
    );
}

export default InfinitySeatLayoutMob